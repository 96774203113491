  .tab_redirect__header {
    margin: 12px 0;
    display: flex;
    align-items: center;
    width: inherit;
    .tab_redirect__subtitle {
      color: #00AB56;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
      text-decoration: none;
    }
  }
