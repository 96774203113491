.button_global{
  padding: 6px 12px ;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 !important;
  border: none;
  outline: none;
  border-radius: 4px;
  &:hover {
    opacity: 0.8;
  }
}

.btn_add {
  background: #00AB56;
  color: white;
}
