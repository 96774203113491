.finance_container {
  .btn_add {
    width: 200px;
    background-color: #00AB56;
    color: white;
    height: 36px;
    border: none;
    outline: none;
    border-radius: 4px;
    &:hover {
      background-color: #0c8f39;
    }
  }
  .finance_container__header {
    margin-bottom: 12px;
    .finance_container__title {
      color: #38383D;
      width: inherit;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
    }
  }
}