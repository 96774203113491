.filter_header {
  height: 72px;
  max-width: 100%;

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #00953D;
  }

  .form_search {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }

    .filter_search {
      .ant-input-affix-wrapper {
        width: auto;
        height: 36px;
        padding: 0 12px;
        outline: none;
        border-radius: 4px;
        background: #FFF;
        .img_prefix {
          width: 20px;
          height: 20px;
        }
        .ant-input {
          padding: 0 !important;
          color: #535353;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }
      }

      img {
        top: 6px;
        left: 10px;
      }
    }

    .filter_select {
      .ant-select-selector {
        min-width: 168px;
        height: 36px !important;
        padding: 0 12px;
        color: #535353;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        outline: none;
        border-radius: 4px;
        background: #FFF;
      }
    }
  }
}