#discount_voucher {
  .modal-dialog {
    max-width: 1000px;
    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .value_voucher__title {
      color:  #EF7752;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      text-transform: uppercase;
    }
  }
  .btn__voucher {
    background-color: #00AB56;
    color: white;
    width: 100%;
    height: 44px;
  }
  .rate-voucher__container {
    padding: 12px 0 12px 9px;
    border-bottom: 1px solid #EBEBF0;
    background: #FFF;
  }
  .button_add_product__voucher {
    display: flex;
    border: none;
    height: 28px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 4px;
    background: #EAFFF4;
    color: var(--active-success, #00AB56);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    &:hover {
      opacity: 0.8;
    }
  }
  .list_product__voucher {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    gap: 10px;
    height: auto;
    min-height: 100px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #DDDDE3;
    background: #FFF;
    .list_product__placeholder {
      color: #808089;
      padding: 10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
    .product_item__voucher {
      display: flex;
      border: none;
      height: 27px;
      padding: 3px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: #EAFFF4;
      color: var(--active-success, #00AB56);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 18px */
    }
  }
  .level_discount__container {
    max-height: 200px;
    padding-right: 8px;
    overflow: scroll;
    &::-webkit-scrollbar {
      height: 100%;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .level_discount__item {
      display: flex;
      padding: 0 4px;
      margin: 6px 0;
      align-items: center;
      gap: 4px;
      flex: 1 0 0;
      border-radius: 4px;
      border: 1px solid var(--input-border-active, #808089);
      background: var(--input-background-default, #FFF);
      .level_discount__item--input {
        border: none;
        outline: none;
        background: #F5F5F5;
        width: 60px;
        margin: 4px;
        overflow: hidden;
        color: var(--input-text-placeholder, #808089);
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
      .level_discount__item--range {
        border-right: 1px solid var(--input-border-active, #808089);
      }
      .level_discount__input--level {
        border: none;
        outline: none;
        background: transparent;
        width: 100%;
        overflow: hidden;
        color: var(--input-text-placeholder, #808089);
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
      .level_discount__input--delete {
        width: 20px;
        opacity: 0.6;
        &:hover{
          opacity: 1;
        }
      }
    }
  }
}
