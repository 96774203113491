.customerData_container {
  .customerData_container__header {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    .customerData_container__title {
      color: #00AB56;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
    }
  }
}