.pick_product__order {
  display: flex;
  justify-content: space-between;
  align-items: start;
  .pick_product__list {
    width: 74%
  }
  .list_product__item {
    max-height: 60vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 24px ;
  }
  .pick_product__total {
    width: 24.5%;
    height: 100%;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.20);
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 38px;
    .header_total {
      width: 100%;
      h2 {
        color: var(--main-b, #000);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 28px */
      }
      .header_total__title {
        border-bottom: 1px solid #393C49;
        padding-bottom: 8px;
        display: flex;
      }
    }
    .list_product {
      height: 400px;
      width: 100%;
      overflow-y: auto;
      .image_product__item {
        width: 49px;
        height: 42px;
        flex-shrink: 0;
        border-radius: 1.784px;
        border: 1.024px solid #C4C4C4;
        margin-right: 12px ;
        object-fit: cover;
        }
      .name_product__item {
        display: flex ;
        justify-content: space-between;
        button {
          border: none;
          outline: none;
          background: transparent;
        }
      }
      .name_product__item {
        width: 100%;
        color: var(--main-b, #000);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 20.8px */
      }
      .btn_delete__product-order {
        opacity: 0.4;
        &:hover {
          opacity: 1;
          transition: all ease-in-out 0.5s;
        }
      }
      .price_product__item {
        color: #838383;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
      }
      .totalPrice_product__item{
        color: var(--black-1, #200E06);
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
      }
      .input_note__order {
        display: flex;
        align-items: center;
        width: 241px;
        height: 42px;
        padding: 14px;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 26px;
        border: 1px solid #ECECEC;
        background: #FAFAFA;
        outline: none;
      }
      .select_qty__product {
        display: flex;
        button {
          border: none;
          background: transparent;
          outline: none;
        }
        .qty_product__order {
          display: flex;
          width: 40px;
          height: 40px;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 0.833px solid #BEBEBE;
          background: #FFF;
        }
      }
    }
  }
  .button_finish__order{
    button {
      border: none;
      outline: none;
      display: flex;
      width: 178px;
      padding: 14px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: var(--main, #EF7752);
      box-shadow: 0 8px 24px 0 rgba(146, 136, 224, 0.30);
      color: var(--base-bg, #FAFAFA);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 19.6px */
    }
    h2 {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      text-transform: uppercase;
    }
    h5 {
      color: var(--main-b, #000);
      text-align: right;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 44.8px */
      span {
        color: var(--main-b, #000);
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        text-decoration-line: underline;
      }
    }
  }
}

.back_order__page {
  display: flex;
  justify-content: start;
  margin-bottom: 12px;
  a {
    border: none;
    outline: none;
    img {
      width: 62px;
      height: 32px;
      background: #e7ebee;
      padding: 4px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
.no_product {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .no_product__title {
    color: #A3A3A3;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    margin-top: 38px;
  }
}
.name_restaurant__order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  h2 {
    color: var(--main-b, #000);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 39.2px */
    margin-bottom: 4px;
  }
  span{
    color: #494949;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  }
}
.name_restaurant__order {
  .input-search-container {
    display: flex;
    width: 392px;
    height: 48px;
    padding: 14px;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    border: 1px solid #FFF;
    background: #f8f5f5;
  }

  img {
    cursor: pointer;
  }

  .search-input {
    border: none;
    outline: none;
    background: transparent;
  }
}
