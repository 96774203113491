#detail_dry_food_check {
  .modal-dialog {
    max-width: 95% !important;
  }
  .bt-action-print {
    background-color: transparent;
    border: none;
    outline: none;
  }
  .detail_request_purchase__content {
    display: flex;
    flex-direction: column;
    .content {
      color: var(--main-b, #000);
      text-align: start;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  .modal-order--content {
    .modal-order--head {
      display: flex;
      margin-bottom: 18px;
      .modal-order--headFirst {
        width: auto;
        .modal-order--name {
          width: 120px;
          color:  #64646D;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 18px */
        }
        .modal-order--value {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 18px */
        }
        span {
          line-height: 180%;
        }
      }
      .modal-order--headSecond {
        width: auto;
        margin-left: 60px;
        .modal-order--name {
          width: 120px;
          color:  #64646D;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 18px */
        }
        .modal-order--value {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 18px */
        }
        span {
          line-height: 180%;
        }
      }
    }
  }
}
@media print {
  body * {
    visibility: hidden;
  }
  #detail_dry_food_check * {
    visibility: visible;
  }
  .bt-action-print * {
    visibility: hidden;
  }
}
