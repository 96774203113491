.voucher_detail_container {
  padding: 0 100px;
  .voucher_detail_container__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
}
.button_copy {
  display: flex;
  width: 100px;
  height: 32px;
  padding: 3px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--active-success, #00AB56);
  color: white;
  background-color: #00AB56;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.modal-voucher--content {
  .modal-voucher--head {
    display: flex;
    margin-bottom: 28px;
    border-radius: 8px;
    border: 1px solid #E7E7E7;
    background: #FFF;
    width: 100%;
    padding: 6px 13px;
    align-items: flex-start;
    gap: 41px;
    .modal-voucher--headFirst {
      width: auto;
      .modal-voucher--name {
        color:  #64646D;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
      }
      .modal-voucher--value {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 18px */
      }
      span {
        line-height: 180%;
        overflow: hidden;
      }
    }
    .modal-voucher--headSecond {
      width: auto;
      .modal-voucher--name {
        color:  #64646D;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
      }
      .modal-voucher--value {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 18px */
      }
      span {
        line-height: 180%;
      }
    }
  }

  .ant-input-affix-wrapper {
    background: #F6F6F6;
    border-radius: unset;
    outline: none;
    border: unset;
    .ant-input {
      background: #F6F6F6;
    }
  }
  .list_search_codeVoucher {
    height: 185px;
    overflow: auto;
    align-items: flex-start;
    gap: 205px;
    flex-shrink: 0;
    background: #29AB56;
    border-radius: 0 0 4px 4px;
    background: var(--light-greyscale-white, #FFF);
    box-shadow: 0  0 10px 0  rgba(132, 132, 132, 0.15);
    .codeVoucher_item {
      display: flex;
      padding: 14px 16px;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;
      color: #959595;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      &:hover {
        color: var(--active-success, #00AB56);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
}