#product_voucher {
  .modal-dialog {
    max-width: 1000px;
    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .value_voucher__title {
      color:  #EF7752;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      text-transform: uppercase;
    }
  }
  .btn__voucher {
    background-color: #00AB56;
    color: white;
    width: 100%;
    height: 44px;
  }
  .button_add_product__voucher {
    display: flex;
    border: none;
    height: 28px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 4px;
    background: #EAFFF4;
    color: var(--active-success, #00AB56);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    &:hover {
      opacity: 0.8;
    }
  }
  .list_product__voucher {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    gap: 10px;
    height: auto;
    min-height: 100px;
    border-radius: 4px;
    border: 1px solid #DDDDE3;
    background: #FFF;
    .list_product__placeholder {
      color: #808089;
      padding: 10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
    .product_item__voucher {
      display: flex;
      border: none;
      height: 27px;
      padding: 3px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: #EAFFF4;
      color: var(--active-success, #00AB56);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 18px */
    }
  }
}
