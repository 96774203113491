.setting_infor__title {
  color: var(--grayscale-g-1, #333);
  text-align: justify;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  padding: 8px 0;
  border-bottom: 1px solid #DBDEE5;
}
.button_save_setting {
  border: none;
  display: flex;
  width: 354px;
  padding: 14px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  background: #29AB56;
  color: var(--black-white-w, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
  text-transform: capitalize;
  &:hover {
    background: #0c8f39;
  }
}
.list_image {
  width: 100%;
  height: 205px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 100%;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #cdcdcd;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .list_ {
    min-width: 100%;
    width: 100%;
    height: 194px;
    display: flex;
    align-items: center;

    .upload {
      width: 170px;
      height: 174px;
      display: flex;
      margin-right: 16px;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer !important;
      border: 0.673077px dashed #DDDDE3;
      border-radius: 8px;
      background: #F5F5FA;

      input {
        width: 170px;
        height: 174px;
        opacity: 0;
        left: 0;
        z-index: 1000;
        cursor: pointer !important;
        overflow: hidden;
        position: absolute;

        &::-webkit-file-upload-button {
          width: 170px;
          height: 174px;
          opacity: 0;
          left: 0;
          z-index: 1000;
          cursor: pointer !important;
          position: absolute;
        }
      }
      img {
        margin: 150px;
      }
    }

    .item {
      width: 170px;
      height: 174px;
      margin-right: 16px;
      position: relative;

      .img {
        width: 168px;
        height: 172px;
        object-fit: cover;
      }

      .close_item {
        top: -12px;
        right: -12px;
        position: absolute;
        cursor: pointer;
      }
    }
  }
}