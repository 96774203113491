.product_card__order {
  display: flex;
  position: relative;
  width: 31%;
  height: 174px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--white-1, #FFF);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.20);
  .img_product__card_container {
    width: 170px;
    height: 150px;
    overflow: hidden;
    flex-shrink: 0;
    border-radius: 8px;
    object-fit: cover;
    cursor: pointer;
    .img_product__card {
      width: 170px;
      height: 150px;
      flex-shrink: 0;
      border-radius: 8px;
      object-fit: cover;
      transition: transform 0.3s ease-in-out;
    }
    &:hover {
      .img_product__card {
        transform: scale(1.5);
      }
    }
  }



  h2 {
    color: var(--black-1, #200E06);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  h5 {
    overflow: hidden;
    color: #828282;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .qty_product__order {
    position: absolute;
    top: 16px;
    left: 16px;
    display: flex;
    width: 48px;
    height: 48px;
    padding: 14px 14px 12px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 100px;
    background: rgba(0, 171, 86, 0.79);
    color: var(--White, #FFF);
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  }
  .price_product {
    color: var(--black-1, #200E06);
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .button_add_product__order {
    border: none;
    background: transparent;
    img {
      width: 54px;
      height: 54px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}