
.side-nav-container {
  width: 268px;
  position: fixed;
  padding: 28px 0;
  height: 100vh;
  //position: relative;
  top: 80px;
  transition: 0.3s;
  background: #FFF;
  box-shadow: 5px 4px 14px 0 rgba(44, 83, 100, 0.06);
}
.side-nav-container-NX {
  width: 69px !important;
}

.nav-brand {
  display: flex;
  color: var(--light);
}

.nav-brand img {
  width: 40px;
  padding: 0 10px;
}

.hamburger {
  display: flex;
  border: none;
  width: 36px;
  height: 36px;
  padding: 9.5px 10px 10.5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 43.17px;
  background: #FFF;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.20);
  position: absolute;
  right: -22px;
  top: 8px;
}
.hamburger-in {
  img {
    transition: transform 300ms ease-in-out;
    animation-duration: 300ms;
    transform: rotate(0deg);
  }
}
.hamburger-out {
  margin-left: 24px;
  img {
    transition: transform 300ms ease-in-out;
    animation-duration: 300ms;
    transform: rotate(180deg);
  }
}

.nav-menu {
  grid-template-rows: repeat(7, 1fr);
  overflow: hidden;
}

.menu-item {
  height: 48px;
  padding: 22px 8px 22px 22px;
  display: flex;
  width: 268px;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  cursor: pointer;
  color: #38383D;
  p {
    font-size: 16px;
    display: block;
    margin-bottom: unset !important;
  }
  img {
    width: 24px;
    margin-right: 18px;
  }
  .icon_down_submenu {
    width: 20px;
    margin: unset;
  }
}

.menu-item-NX {
  margin: auto;
  width: 100%;
  justify-content: start;
  img {
    width: 24px;
    margin-right: unset;
  }
}
.menu-item:hover {
  background-color: #f6c0b6;
}
.menu_item__active {
  p {
    color: white !important;
  }
  background-color: #EF7752;
}

.submenu_item__active {
  p {
    color: #EF7752 !important;
  }
}


.nav-footer {
  width: 100%;
  height: 87px;
  position: absolute;
  bottom: 0;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 1fr;
}

.nav-details {
  display: flex;
}
.nav-details img {
  width: 50px;
  padding: 0 20px;
}

.nav-footer-user-name {
  font-size: 18px;
  font-weight: 900;
}

.nav-footer-user-position {
  margin-top: -15px;
  color: var(--gray);
}
.logout-icon {
  width: 30px;
  margin: auto;
  border-radius: 90px;
  padding: 20px;
  margin-left: 5px;
}
.logout-icon:hover {
  background-color: var(--primary);
}
.submenu_container {
  display: flex;
  flex-direction: column;
  padding-left: 48px;
  a {
    text-decoration: none;
    padding: 10px 16px;
    font-size: 15px;
    width: 220px;
    &:hover {
      color: #EF7752 !important;;
    }
  }
}
.active_submenu {
  transition: transform 300ms ease-in-out;
  animation-duration: 300ms;
  transform: rotate(-180deg);
}
.not_active_submenu {
  transition: transform 300ms ease-in-out;
  animation-duration: 300ms;
  transform: rotate(0deg);
}