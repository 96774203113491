.table_container {
  position: relative;
  border: none;
  outline: none;
  background: transparent;

  .name_table_white {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    color:  #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
  .name_table {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    color:  #C4C4CF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
}