.dashboard_container {
  .dashboard_container__header {
    margin-bottom: 12px;
    .dashboard_container__title {
      color: #00AB56;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
    }
  }
  .view_detail__btn {
    display: flex;
    margin: auto;
    width: 78px;
    height: 27px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--active-success, #00AB56);
    color: var(--active-success, #00AB56);
    background-color: white;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
    &:hover {
      background: #00AB56;
      color: white;
    }
  }
}

