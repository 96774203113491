#tab_header__product {
  width: 100%;
  .tab_header {
    overflow: auto;
    button {
      background: transparent;
      border: none;
    }
    .tab {
      margin-right: 18px;
      padding: 4px 0;
      cursor: pointer;
      transition: color  0.3s ease-in-out;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 19.6px */
      &:hover {
        padding-bottom: 6px;
      }
    }

    .tab.active {
      border-bottom: 2px solid #EA7C69;
      color: var(--primary-color, #EA7C69);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 19.6px */
      &:hover {
        padding-bottom: 4px;
      }
    }
  }
  .tab-indicator {
    height: 2px; /* Độ dày của border-bottom */
    background-color: blue; /* Màu của border-bottom */
    transition: all 0.3s ease; /* Hiệu ứng chuyển đổi mượt mà */
  }
}
