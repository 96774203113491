.chart_feedback__container {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  .chart_feedback__container--title {
    width: 162px;
    display: flex;
    justify-content: start;
    align-items: center;
    color: var(--gray-8, #515158);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    margin-right: 28px;
  }
  .chart_feedback__item,.chart_feedback__item--2, .chart_feedback__item--3, .chart_feedback__item--4{
    cursor: pointer;
    height: 40px;
  }
  .chart_feedback__item:nth-child(2) {
    background-color: #2e62c4;
  }
  .chart_feedback__item:nth-child(3) {
    background-color: #09D;
  }
  .chart_feedback__item:last-child {
    background-color: #F93;
  }


  .chart_feedback__item--2:nth-child(2) {
    background-color: #2e62c4;
  }
  .chart_feedback__item--2:nth-child(3) {
    background-color: #09D;
  }
  .chart_feedback__item--2:last-child {
    background-color: #7e9f9f;
  }

  .chart_feedback__item--3:nth-child(2) {
    background-color: #2e62c4;
  }
  .chart_feedback__item--3:nth-child(3) {
    background-color: #09D;
  }
  .chart_feedback__item--3:last-child {
    background-color: #f5cd79;
  }

  .chart_feedback__item--4:nth-child(2) {
      background-color: #2e62c4;
    }
  .chart_feedback__item--4:last-child {
      background-color: #cc3e58;
    }


}