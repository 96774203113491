.dashboard_container {
  width: 100%;
  .dashboard_container__revenue-header {
    height: 120px;
    border-bottom: 2px solid #F0F0F0;
    .dashboard_container__revenue-title {
      color: var(--Black, #000);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 50%;
      padding: 18px 26px;
      h1 {
        color: var(--Black, #000);
        text-align: right;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      h2 {
        color: var(--Black, #000);
        text-align: right;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .title-1 {
        color: var(--Gray, #4F4F4F);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .title-2{
        color: var(--Black, #000);
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .dashboard_container__revenue-line {
    margin-top: 8px;
    flex-shrink: 0;
    background: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    border-radius: 8px;
  }
  .dashboard_container__revenue-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .dashboard_container__revenue-head {
      width: 100%;
      display: flex;
      padding: 9.161px 27.484px;
      align-items: flex-start;
      gap: 13.742px;
      opacity: 0.7;
      background: var(--gray-50, #F5F6F7);
      .dashboard_container__revenue-head--item {
        width: 33.33%;
        display: flex;
        justify-content: center;
        color: var(--gray-700, #4A5154);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 10px */
        text-transform: uppercase;
      }
    }
    .dashboard_container__revenue-row {
      width: 100%;
      display: flex;
      padding: 9.161px 27.484px;
      align-items: flex-start;
      gap: 13.742px;
      opacity: 0.7;
      background: transparent;


      .dashboard_container__revenue-row--item {
        width: 33.33%;
        display: flex;
        justify-content: center;
        color: var(--gray-700, #4A5154);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 10px */
      }
      .item-1 {
        color: var(--calendar-label-black, #191B1C);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.903px; /* 143.145% */
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .item-2 {
        color: var(--gray-700, #4A5154);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.903px; /* 143.145% */
      }
      .item-3 {
        color: var(--success-500, #0FAF62);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22.903px; /* 143.145% */
      }
    }
  }
  .note_structure__product {
    .title-color-chart {
      line-height: 22.903px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .note-color-chart {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: red;
  }
  .title-color-chart {
    color: var(--Black, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .top-product__header {
    width: 100%;
    padding: 32px 18px;
    display: flex;
    justify-content: center;
    color: #333;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 114.286% */
  }
  .top-product__container {
    width: 100%;
    padding: 18px 52px;
    .first_product {
      display: flex;
      .medal_icon {
        width: 24px;
        height: 32px;
      }
      .first_product__content {
        padding-bottom: 10px;
        border-bottom: 1px solid #EEE;
      }
      .img_top__product {
        width: 70px;
        height: 69px;
        flex-shrink: 0;
        object-fit: cover;
        border-radius: 10px;
      }
      h2 {
        color: #333;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 120% */
      }
      span {
        color: #B78A28;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.5px; /* 150% */
      }
    }
    .last_product {
      display: flex;
      .medal_icon {
        width: 24px;
        height: 32px;
        color: #333;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 213.333% */
      }
      .last_product__content {
        padding: 16px 0;
      }
      .last_product__content--border {
        border-bottom: 1px solid #EEE;
      }
      .img_top__product {
        width: 70px;
        height: 69px;
        flex-shrink: 0;
        object-fit: cover;
        border-radius: 10px;
      }
      h2 {
        color: #333;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 120% */
      }
      span {
        color: #666;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.5px; /* 150% */
      }
    }
  }
  .select_month__filter {
    width: 150px;
  }
}