.feedback_container {
  .table-responsive {
    padding: 0 32px;
  }
  .feedback_container__header {
    margin-bottom: 12px;
    .feedback_container__title {
      color: #00AB56;
      width: inherit;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
    }
  }
  .view_detail__btn {
    display: flex;
    margin: auto;
    width: 78px;
    height: 27px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--active-success, #00AB56);
    color: var(--active-success, #00AB56);
    background-color: white;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
    &:hover {
      background: #00AB56;
      color: white;
    }
  }
  .feedback_container--note-title {
    color: var(--active-success, #515158);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

  }
  .feedback_container--note {
    width: 50px;
    height: 20px;
    margin-right: 8px;
  }

}

.btn_add__feedback {
  display: inline-flex;
  height: 36px;
  padding: 3px 12px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #EAFFF4;
  border: none;
  color: var(--active-success, #00AB56);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  img {
    width: 18px;
    height: 18px;
  }
}