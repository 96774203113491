.staff_container {
  .view_detail__btn {
    display: flex;
    margin: auto;
    width: 78px;
    height: 27px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--active-success, #00AB56);
    color: var(--active-success, #00AB56);
    background-color: white;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
    &:hover {
      background: #00AB56;
      color: white;
    }
  }
}

.btn-edit__row {
  display: flex;
  width: 58px;
  padding: 3px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--active-success, #00AB56);
  color: var(--active-success, #00AB56);
  background-color: white;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.btn-edit__row--cancel {
  display: flex;
  width: 58px;
  height: 32px;
  padding: 3px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: none;
  color: var(--active-success, #676b69);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.btn-edit__row--save {
  display: flex;
  width: 58px;
  height: 32px;
  padding: 3px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--active-success, #00AB56);
  color: white;
  background-color: #00AB56;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.update-infor__staff--input {
  width: 100%;
}