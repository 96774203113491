:root {
  --gradient-start: #C84E89;
  --gradient-end: #F15F79;
}
.signUp_main {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .background-signup__container {
    position: relative;
  }
  .content-signup {
    width: 100%;
    height: 100%;
    padding: 50px;
    display: flex;
    align-items: center;
    .content-signup__container {
      h1 {
        color:  #EF7752;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }
      .content-signup__subtitle {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
      .content-signup__btn {
        display: flex;
        align-items: center;
        a {
          text-decoration:none;
          padding: 8px 12px;
          margin-right: 28px;
          border: none;
          outline: none;
          border-radius: 4px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          &:hover {
            opacity: 0.8;
          }
        }
        .btn-redirect {
          width: 165px;
          height: 48px;
          background: #EF7752;
          color: #FFF;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }
        .btn-customer {
          width: 150px;
          height: 48px;
          background-color: white;
          border: 1px solid;
          border-image: linear-gradient(90deg, #C84E89 0%, #F15F79 100%);
          border-image-slice: 1;
          color: #C84E89;
          font-size: 16px;
          font-weight: bold;
          padding: 10px 20px;
          cursor: pointer;
          span {
            background: var(--3, linear-gradient(90deg, #C84E89 0%, #F15F79 100%));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }

  }
}
