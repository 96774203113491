.menu_action__container {
  display: flex;
  justify-content: center;
  position: relative;

  .btn_toggle__action {
    border: none;
    outline: none;
    display: flex;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 4px;
    background: #F3F3F3;
    color: var(--gray-7, #64646D);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    &:hover {
      opacity: 0.8;
    }
  }
    .menu-action_dropdown {
      width: 200px;
      max-height: 0;
      overflow: hidden;
      background: #FFF;
      position: absolute;
      top: 34px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
      border-radius: 6px;
    }

    .menu-action_dropdown hr {
      border: 0.5px solid var(--green);
    }

    .active {
      z-index: 99999999999;
      max-height: 504px;
      border: 1px solid var(--gray-100, #E5E7E8);
      background: var(--gray-white, #FFF);
      box-shadow: 0px 4px 24px 0px rgba(25, 27, 28, 0.16);
    }

    .dropdown-action__item {
      width: 100%;
      img {
        margin-right: 10px;
      }
      .menu_action__error {
        color: #FF424F;
      }
      .menu_action__success {
        color: #00AB56;
      }
      span {
        color: var(--gray-600, #626C70);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      .divice_menu__action {
        width: 100%;
        height: 1px;
        background: #E5E7E8;
      }
    }

    .dropdown-action__item a, .dropdown-action__item button {
      width: 100%;
      border: none;
      outline: none;
      border-radius: 0 !important;
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: 0.9rem;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        background-color: rgba(243, 238, 238, 0.84);
      }
    }
}
