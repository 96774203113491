.dashboard_container {
  .dashboard_container__header {
    margin-bottom: 12px;
    .dashboard_container__title {
      color: #00AB56;
      width: inherit;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
    }
  }
  .service_item_container {
      background-color: rgba(241, 239, 239, 0.56);
  }
  .detail_order_checkout {
    border: none;
    background-color: transparent;
    color: var(--Main, #EF7752);
    text-align: right;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%; /* 21px */
    text-decoration-line: underline;
  }
  .view_detail__btn {
    display: flex;
    margin: auto;
    width: 78px;
    height: 27px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--active-success, #00AB56);
    color: var(--active-success, #00AB56);
    background-color: white;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
    &:hover {
      background: #00AB56;
      color: white;
    }
  }
  .dashboard_container_revenue {
    flex-shrink: 0;
    background: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    border-radius: 8px;
    .dashboard_container__revenue-header {
      height: 80px;
      border-bottom: 2px solid #F0F0F0;
      .dashboard_container__revenue-title {
        color: var(--Black, #000);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 50%;
        padding: 18px 26px;
      }
    }
  }
}