#detail_request_for_work {
  .modal-dialog {
    max-width: 800px;
    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .detail_request_for_work__title {
      color:  #EF7752;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      text-transform: uppercase;
    }
  }
}
