.custom-table {
  margin-top: 20px;
  .ant-table-container {
    .ant-table-thead {
      .ant-table-cell {
        text-align: center;
        background-color: #ECF0FF;
        color: #000;
        border-right: none !important;
        border-bottom: none !important;
        border-inline-end: none !important;
      }

      .ant-table-row-expand-icon-cell {
        width: 40px !important;
      }

      .ant-table-cell-scrollbar {
        box-shadow: none;
      }
    }

    .ant-table-body {
      .ant-table-cell {
        .ant-table-expanded-row-fixed {
          background-color: #f3fbfe;
        }
      }
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background: #c5c5c5;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
      }
    }

    .ant-table-small {
      margin: 5px 5px 5px 80px !important;
      .ant-table-thead {
        .ant-table-cell {
          text-align: center;
          color: rgba(0, 0, 0, 0.88);
          font-weight: 600;
          background: #00b2e1;
          border-bottom: 1px solid rgba(5, 5, 5, 0.06);
        }
      }
    }
  }
  .ant-table-content {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: #c5c5c5;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
    }
  }
}
