#view_detail_order {
  .modal-dialog {
    max-width: 1400px !important;
  }
  .modal-dialog {
    .modal-content {
      padding: 30px ;
      .modal-header {
        padding: unset;
      }
      .modal-order--content {
        .modal-order--head {
          display: flex;

          .modal-order--headFirst {
            width: 290px;
            .modal-order--title {
              display: flex;
              width: 190px;
              padding: 2px 0;
              margin-bottom: 10px;
              align-items: flex-start;
              gap: 10px;
              border-bottom: 1px solid var(--gray-5, #A6A6B0);
              color: #64646D;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
            }
            .modal-order--name {
              width: 90px;
              color:  #64646D;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 18px */
            }
            .modal-order--value {
              color: #000;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 18px */
            }
            span {
              line-height: 180%;
            }
          }
          .modal-order--headSecond {
            width: 495px;
            margin-left: 100px;
            .modal-order--title {
              display: flex;
              width: 100%;
              padding: 2px 0;
              margin-bottom: 10px;
              align-items: flex-start;
              gap: 10px;
              border-bottom: 1px solid var(--gray-5, #A6A6B0);
              color: #64646D;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
            }
            .modal-order--name {
              color:  #64646D;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 18px */
            }
            .modal-order--value {
              color: #000;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 18px */
            }
            span {
              line-height: 180%;
            }
          }
        }
      }
    }
    .modal-footer {
      padding: 0.75rem 0;
    }
    .btn_action__checkout {
      display: flex;
      margin-left: 12px;
      height: 40px;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid var(--active-success, #00AB56);
      color: var(--active-success, #00AB56);
      background-color: white;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-decoration: none;
      &:hover {
        opacity: 0.8;
      }
    }
    .btn_action__checkout__finish {
      display: flex;
      margin-left: 12px;
      height: 40px;
      padding: 8px 36px;
      width: 250px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: none;
      color: white;
      background-color: #00AB56;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-decoration: none;
      &:hover {
        opacity: 0.8;
      }
    }

    .input-search-container {
      display: flex;
      width: 392px;
      height: 32px;
      padding: 8px;
      align-items: center;
      gap: 8px;
      border-radius: 30px;
      border: 1px solid #FFF;
      background: #f8f5f5;
      img {
        cursor: pointer;
      }

      .search-input {
        border: none;
        outline: none;
        background: transparent;
        color: #5e6e78;
      }
    }
    .input-search-voucher-container {
      display: flex;
      justify-content: space-between;
      width: 250px;
      height: 32px;
      padding: 8px;
      align-items: center;
      gap: 8px;
      border-radius: 30px;
      border: 1px solid #FFF;
      background: #f8f5f5;
      img {
        cursor: pointer;
      }

      .search-input {
        border: none;
        outline: none;
        background: transparent;
        color: #5e6e78;
      }
    }

    .status_payment {
      color: var(--positive-1, #00BC3C);
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}




.box_no__user {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5e6e78;
  font-weight: 500;
  font-style: italic;
}
@media print {
  body * {
    visibility: hidden;
  }
  #view_detail_order * {
    visibility: visible;
  }
  .modal-footer * {
    visibility: hidden !important;
  }
  .input-search-container * {
    visibility: hidden !important;
  }
}