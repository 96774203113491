#add_info_receiver_transport {
  background-color: rgba(89, 89, 86, 0.4);
  .modal-dialog {
    max-width: 800px;
    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .value_info__receiver--title {
      color:  #EF7752;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      text-transform: uppercase;
    }
  }
  .btn_submit__info--receiver {
    background-color: #00AB56;
    color: white;
    width: 100%;
    height: 44px;
  }
}
