#add_product_voucher__discount {
  z-index: 99999999999999 !important;
  background-color: rgba(0, 0, 0, 0.5);
  .modal-body {
    padding: 1rem 0 0;
  }
  .modal-dialog {
    max-width: 500px;
    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .value_voucher__title {
      color:  #EF7752;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      text-transform: uppercase;
    }
  }
  .btn__voucher {
    background-color: #00AB56;
    color: white;
    width: 100%;
    height: 44px;
  }
}
