#select_room_order {
  .modal-dialog {
    max-width: 1286px;
  }
}

.modal-dialog {
  .modal_select_room__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    .button_move_left {
      border: none;
      outline: none;
    }
    .modal_select_room__title {
      color:  #EF7752;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      margin-bottom: 42px;
    }
    .modal_select_room__cardContainer {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: start;
      align-items: center;
      gap: 25px;
      .modal_select_room__card {
        display: flex;
        border: none;
        outline: none;
        width: 287px;
        height: 90px;
        padding: 5.5px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        background:  #00AB56;
        &:hover {
          opacity: 0.8;
        }
        .modal_select_room__cardTitle {
          color: #FFF;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }
      }
      .modal_room_full__card {
        display: flex;
        border: none;
        outline: none;
        width: 287px;
        height: 90px;
        padding: 5.5px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        background:  #FF424F;
        &:hover {
          opacity: 0.8;
        }
        .modal_select_room__cardTitle {
          color: #FFF;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
        }
      }
    }
  }
}