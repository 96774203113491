.btn_select_restaurant {
  border: none;
  display: flex;
  width: 250px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #EF7752;
  color: var(--main-b, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  &:hover {
    background: #c95b39;
  }
}