.button_save_change__point {
  border: none;
  display: flex;
  width: 154px;
  padding: 14px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  background: #29AB56;
  color: var(--black-white-w, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
  text-transform: capitalize;
  &:hover {
    background: #0c8f39;
  }
}
.text_range_change__point {
  background-color: #f8f5f5;
  padding: 4px 12px;
  border-radius: 2px;
  color: #5e6e78;
  font-weight: 500;
}