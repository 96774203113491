#add_edit_finance {
  .modal-dialog {
    max-width: 1000px;
    .header-form__finance {
      padding-bottom: 4px;
      border-bottom: 1px solid #B3B3B3;
      background: #FBFBFB;
    }
    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .value_voucher__title {
      color:  #EF7752;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      text-transform: uppercase;
    }
  }
  .btn__voucher {
    background-color: #00AB56;
    color: white;
    width: 100%;
    height: 44px;
  }
  .acocunt_not_exits {
    border-color: #7B61FF;
  }
  .error_account_not_exits {
    color: #7B61FF ;
    font-size: 13px;
  }
}
