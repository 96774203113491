.tracking_card__order--ship {
  .status_des {
    color: var(--neutral-2, #4D4D4D);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    .status_des__time {
      color: #929292;
      text-align: right;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}