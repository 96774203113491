#add_username_account {
  .modal-body {
    padding: 1rem 0 0;
  }
  .modal-dialog {
    max-width: 500px;
    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .value_voucher__title {
      color:  #EF7752;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      text-transform: uppercase;
    }
  }
  .btn__continue {
    background-color: #00AB56;
    color: white;
    height: 44px;
    &:hover {
      background-color: #038c49;
    }
  }
  .btn__continue_error{
    background-color: #EF7752;
    color: white;
    height: 44px;
    &:hover {
      background-color: #c95f3f;
    }
  }
}
