.header-top {
        width: 100%;
        height: 80px;
        background: #FFFFFF;
        position: fixed;
        z-index: 999;

        .content-header {
                width: 100%;
                height: 80px;
                padding: 0 24px 0 18px;
                background-color: white;

                h4 {
                        font-weight: 500;
                        font-size: 18px;
                        color: #000000;
                        margin-bottom: 0;
                }

                .info_farm {
                        display: flex;
                        margin-right: 34px;
                        align-items: center;

                        span {
                                font-weight: 400;
                                font-size: 18px;
                                line-height: 21px;
                                color: #000000;
                        }

                        .icon {
                                margin-right: 12px;

                                img {
                                        width: 28px;
                                }
                        }
                }

                .user_profile {
                        width: 256px;
                        height: 59px;
                        padding: 0 16px;
                        background: #F2F8FF;
                        border-radius: 12px;
                        .action_info {
                                width: 32px;
                                height: 32px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                        }

                        .box_user_info.show {
                                opacity: 1;
                                display: block;
                                animation-duration: 0.5s;
                                animation-name: showEffect;
                        }

                        .avatar {
                                width: 46px;
                                height: 46px;
                                margin-right: 8px;

                                img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        border-radius: 50%;
                                }
                        }

                        .info_name {
                                p.name {
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 28px;
                                        color: #1F1F1F;
                                        margin-bottom: 0;
                                }

                                p.manage {
                                        margin: 0;
                                        top: -5px;
                                        height: 28px;
                                        position: relative;
                                        font-weight: 500;
                                        font-size: 12px;
                                        line-height: 28px;
                                        color: #5E6E78;
                                }
                        }

                        .box_user_info {
                                top: 60px;
                                right: 8px;
                                opacity: 0;
                                display: none;
                                position: absolute;
                                z-index: 100;
                                width: 155px;
                                height: 75px;
                                background: #FFFFFF;
                                transition: 0.5s;
                                padding: 8px 0 0;
                                box-shadow: 0px 0px 15px rgba(12, 47, 76, 0.15);

                                &::before {
                                        border-width: 9px;
                                        border-style: solid;
                                        border-color: transparent transparent rgb(255, 255, 255);
                                        border-image: initial;
                                        margin-left: 45px;
                                        bottom: 100%;
                                        left: 50%;
                                        content: "";
                                        height: 0px;
                                        width: 0px;
                                        position: absolute;
                                        pointer-events: none;
                                }

                                a {
                                        width: 100%;
                                        height: 30px;
                                        font-weight: 400;
                                        font-size: 15px;
                                        line-height: 21px;
                                        color: #29AB56;
                                        padding: 0 14px;
                                        text-decoration: none;
                                        display: flex;
                                        align-items: center;

                                        &:hover {
                                                color: #29AB56;
                                                background: #F0F0F0;
                                        }
                                }
                        }

                }
                .notice {
                        cursor: pointer;
                        padding: 12px 24px;
                        &:hover .box_restaurant {
                                opacity: 1;
                                display: block;
                                animation-duration: 0.5s;
                                animation-name: showEffect;
                        }
                        .box_restaurant {
                                top: 52px;
                                right: -28px;
                                opacity: 0;
                                display: none;
                                position: absolute;
                                z-index: 100;
                                width: 235px;
                                background: #FFFFFF;
                                transition: 0.5s;
                                box-shadow: 0px 0px 15px rgba(12, 47, 76, 0.15);
                                &::before {
                                        border-width: 9px;
                                        border-style: solid;
                                        border-color: transparent transparent rgb(255, 255, 255);
                                        border-image: initial;
                                        margin-left: 45px;
                                        bottom: 100%;
                                        left: 50%;
                                        content: "";
                                        height: 0px;
                                        width: 0px;
                                        position: absolute;
                                        pointer-events: none;
                                }
                                .box_restaurant__header {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        border-bottom: 1px solid #c5c5c5;
                                        padding: 8px 14px;
                                        color: #595858;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 700;
                                        line-height: normal;
                                }
                                button {
                                        border: none;
                                        width: 100%;
                                        overflow: hidden;
                                        height: 36px;
                                        font-weight: 400;
                                        font-size: 15px;
                                        line-height: 21px;
                                        background-color: white;
                                        color: #29AB56;
                                        padding: 14px;
                                        text-decoration: none;
                                        display: flex;

                                        align-items: center;

                                        .logo {
                                                width: 28px;
                                                height: 28px;
                                                border-radius: 50%;
                                                object-fit: cover;
                                                margin-right: 14px;
                                        }
                                        &:hover {
                                                color: #29AB56;
                                                background: #F0F0F0;
                                        }
                                }
                        }

                }
                .menu_toggle {
                        .image_toggle {
                                        position: relative;
                                        width: 3rem;
                                        height: 3rem;
                                        border-radius: 50%;
                                        background-size: cover;
                        }

                                .profile-dropdown-list {
                                        width: 278px;
                                        max-height: 0;
                                        overflow: hidden;
                                        border-radius: 30px;
                                        background: #FFF;
                                        position: absolute;
                                        top: 78px;
                                        right: 28px;
                                        transition: max-height 0.2s;
                                        display: flex;
                                        justify-content: start;
                                        align-items: center;
                                        flex-wrap: wrap;
                                }

                                .profile-dropdown-list hr {
                                        border: 0.5px solid var(--green);
                                }

                                .active {
                                        max-height: 504px;
                                        border: 3.5px solid var(--grey-5, #D7D7D7);
                                }

                                .profile-dropdown-list-item {
                                        padding: 0.75rem 0;
                                        width: 90px;
                                        transition: background-color 0.2s ease-in, padding-left 0.2s;
                                        span {
                                                color:  #181818;
                                                font-size: 12px;
                                                margin-top: 4px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;
                                        }
                                }

                                .profile-dropdown-list-item a {
                                        display: flex;
                                        align-items: center;
                                        text-decoration: none;
                                        font-size: 0.9rem;
                                        font-weight: 500;
                                        cursor: pointer;
                                }
                                .profile-dropdown-list-item:hover {
                                        padding-left: 1rem;
                                        background-color: var(--secondary-light);
                                }
                        }
                }

}


@keyframes showEffect {
        from {
                opacity: 0;
                display: none;
        }

        to {
                opacity: 1;
                display: block;
        }
}

@media screen and (max-width: 1600px) {
        .header-top {
                height: 80px;
        }
}