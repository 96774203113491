#add_request_purchase, #add_import_export_request, #add_import_buy_yourself, #confirm_supplies_import {
  .modal-dialog {
    max-width: 1200px !important;
  }
  .button_save {
    display: flex;
    margin-top: 12px;
    border: none;
    outline: none;
    width: 155px;
    height: 44px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 2px;
    background: var(--main, #00953D);
    color: var(--w, #FFF);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    &:hover {
      background: var(--main, #027732);
    }
  }
  .w-28{
    width: 28%;
  }
}