
.body-wrapper {
  transition: width 0.25s ease, margin 0.25s ease, padding 0.25s ease;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  background: #F9FCFF;
  padding-top: 80px;
}

.main-content {
  width: 100%;
  padding: 18px 30px 18px 30px ;
}
.footer {
  position: fixed;
  z-index: 150;
  bottom: 0;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EAFFF3;
  color: #373737;
  text-align: center;
  font-family: Roboto, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.cursor_pointer {
  cursor: pointer;
}

.icon-action {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.icon_action {
  width: 17px;
  height: 17px;
  margin-right: 8px;
}
.table td .action,
.MuiTable-root td .action {
  display: flex;
  align-items: center;
  justify-content: center;

  button.bt_action {
    border: none !important;
    background-color: transparent !important;
    padding: 6px !important;

    &:focus-visible,
    &:focus {
      outline: none !important;
    }

    a {
      display: inline-block !important;
      margin-right: 2px !important;
    }

    img {
      width: 24px !important;
      height: 24px !important;
      border-radius: 0 !important;
    }
  }
}

.table td,
.table th {
  vertical-align: middle;
}


.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-60 {
  width: 60%;
}

.text-error {
  color: red;
}

$grid-breakpoints-lg: 992px !default;

.custom-scrollbar {
  @media (min-width: $grid-breakpoints-lg) {
    &::-webkit-scrollbar {
      width: 6px;
      height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #a8a8a8;
    }
  }
}


.table-responsive {
  max-width: 100%;
  border-collapse: collapse;
}
.table-part-responsive {
  overflow: auto;
  max-height: 400px;
  max-width: 100%;
  border-collapse: collapse;
}

.error {
  font-size: 13px;
  color: red;
  display: inline-block;
  width: 100%;
  height: 20px;
  margin-bottom: 0;
  padding-top: 4px;
}

.border_error , .date_error{
  border-radius: 4px;
  border: 1px solid red !important;
  .ant-select-selector{
    border: none !important;
  }
  &:focus-visible,
  &:focus {
    outline: none !important;
  }
}

.color-green {
  color: #00AB56 !important;
}

.bg-green {
  color: #00AB56 !important;
}


.color-red {
  color: #FF424F !important;
}

.color-pending {
  color: #515158 !important;
}

.color-purple {
  color: #7B61FF !important;
}

.fw-500 {
  font-weight: 500 !important;
}


.custom_scroll {
  width: 100%;
  max-height: 450px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #bebebe;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #7e7e7e;
  }

  input{
    width: 100%;
    height: 38px;
    font-size: 14px;
    padding: 0 14px;
    border-radius: 4px;
    border: 1px solid #ced4da;
  }
}

.button_add_form_table {
  display: flex;
  height: 36px;
  padding: 5.5px 12px;
  justify-content: center;
  align-items: center;
  background-color: white;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #00AB56;
  color: #00AB56;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  img {
    width: 20px;
    height: 20px;
  }
  &:hover {
    opacity: 0.6;
  }
}
.btn_delete_form_table {
  position: absolute;
  top: 25%;
  right: -32px;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}
.form-group {
  margin-bottom: 12px;
}
.form-label {
  color: #27272A;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  margin-bottom: 5px;
}

.modal-header {
  border: none;

  h5 {
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
    color: #333333;
  }

  .close {
    opacity: 1 !important;

    span {
      font-size: 26px !important;
      font-weight: 400;
    }
  }
}
label{
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
.fixed_column{
  position: sticky;
  right: 0;
}
input.form-control{
  font-size: 14px;
  height: 38px;
}

.modal-footer {
  border: none;
}
.table-responsive {
  border-radius: 10px;
  background: #FFF;

  /* Light */
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
  table {
    max-width: 100%;
    border: none !important;
  };
  table tbody {
    background-color: white!important;
  }
  table th {
    position: sticky;
    top: -3px;
    padding: 0.75rem 0.9375rem !important;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    border: none !important;
    background: #ECF0FF !important;
  }
  table td {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px !important;
    max-width: 350px;
    white-space: normal !important;
    border: none !important;
    padding: 10px 12px !important;

    p.des {
      width: 100%;
      height: 40px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
}
.ant-table-container {
  border: none !important;
}

.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td {
  border-inline-end: none !important;
}
.ant-table-wrapper .ant-table-tbody >tr >td {
  border-bottom: none !important;
  padding: 12px 16px !important;
}
.time_range {
  width: 100%;
  background-color: white;
  height: 38px !important;
  font-size: 14px !important;

  input {
    width: 100%;
    font-size: 14px !important;
    cursor: pointer;
    padding: 0.375rem 1.8rem 0.375rem 0.75rem !important;
    margin-left: 5px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    &:focus-visible{
      outline: 1px solid #ced4da;
    }
  }

  i {
    top: 35%;
    right: 14px;
    position: absolute;
  }
}

.react-datepicker-wrapper {
  width: 100%;
  height: 100%;

  .react-datepicker__input-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 100;

    input {
      padding: 0.375rem 0.75rem;
      font-size: 14px;
      width: 100% !important;
      margin: 0;
      height: 100%;
      background-color: transparent;

      &:disabled {
        background-color: rgba(193, 193, 193, 0.6);
      }
    }
  }

}

.react-datepicker-popper {
  z-index: 1200 !important;
}

.table-form-responsive {
  table {
    max-width: 100%;
  };
  table th {
    padding: 0.75rem 0.9375rem !important;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    border: 1px solid #DDDDE3 !important;
    background: #F6FFD1 !important;

    &:last-child {
      border-right: none;
    }
  }
  table td {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px !important;
    max-width: 350px;
    white-space: normal !important;
    border: 1px solid #DDDDE3 !important;

    p.des {
      width: 100%;
      height: 40px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

}
table tbody.ant-table-tbody tr.ant-table-measure-row{
  td{
    padding: 0 !important;
  }
}
.table-item-responsive {
  table th {
    background: #F6F6F6 !important;
  }
}
.table-form-responsive {
  table th {
    background: #F6FFD1 !important;
  }
}
.modal {
  z-index: 1049 !important;
}
.modal_full {
  padding: 0 !important;

  .modal-dialog {
    margin: 0 !important;
    padding: 0 !important;
    max-width: 100%;
    height: 100vh;
    background-color: white;

    .modal-content {
      padding: 0 !important;
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      pointer-events: auto;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid #f3f3f3;
      border-radius: 0.3rem;
      outline: 0;
    }

    .close {
      width: 92px;
      height: 32px;
      right: 16px;
      top: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background: #FFF;
      border: none;
      border-radius: 4px;
      opacity: 1;
      z-index: 1060;
      p {
        color: #000000;
        font-weight: 400;
        font-size: 16px;
        padding-left: 5px;
      }
    }
  }
}
button.btn-cancel {
  border: 1.5px solid #858585;
  color: #858585;
  background-color: transparent;
  width: 186px;
  margin: 0 8px;
  &:hover {
    opacity: 0.8;
  }
}

button.btn-confirm {
  color: white;
  background: #EF7752;
  width: 186px;
  margin: 0 8px;

  &:hover {
    opacity: 0.8;
    color: white;
  }
}
.ant-picker {
  width: 100%;
  z-index: 10 !important;
  padding: 7px 12px;
  border-radius: 0.25rem;
}
.ant-picker.ant-picker-borderless {
  border: 1px solid #d9d9d9 !important;
}
.ant-select {
  width: 100%;
  .ant-select-selector {
    border-radius: 0.25rem;
    height: 38px !important;
  }
}
.ant-select-selection-search-input {
  height: 38px !important;
}
.ant-select-clear {
  top: 42% !important;
}
.ant-select-selection-placeholder , .ant-select-selection-item {
  line-height: 34px !important;
}
.ant-space-compact {
  width: 100%;
  border-radius: 0.25rem;
}
.ant-input {
  width: 100%;
  border-radius: 0.25rem;
  padding: 7px 12px !important;
}
.ant-checkbox-wrapper {
  color: #111;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-transform: capitalize;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #00953D;
  border-color: transparent;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
  border-color: #00953D;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00953D;
  border-color: #00953D;
}
.ant-radio-wrapper {
  color: #111;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  //text-transform: capitalize;
}
.ant-radio-wrapper:not(.ant-radio-wrapper-disabled):hover .ant-radio-checked:not(.ant-radio-disabled) .ant-radio-inner {
  background-color: #00953D;
  border-color: transparent;
}
.ant-radio-wrapper:not(.ant-radio-wrapper-disabled):hover .ant-radio-inner {
  border-color: #00953D;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: #00953D;
  border-color: #00953D;
}

.modal_title{
  color: #EF7752;
  font-weight: 600;
  font-size: 16px;
}

.ant-input-affix-wrapper {
  width: 100%;
  border-radius: 0.25rem;
  padding: 1px !important;
  .ant-input {
    margin-right: 12px;
  }
  span{
    padding: 0 12px;
    cursor: pointer;
  }
}
.ant-select-selection-item {
  align-items: center !important;
}
.ant-select-selection-overflow {
  flex-wrap: unset !important;
  overflow-x: scroll !important;
  @media (min-width: $grid-breakpoints-lg) {
    &::-webkit-scrollbar {
      width: 6px;
      height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #a8a8a8;
    }
  }
}
button {
  .ant-spin .ant-spin-dot-item {
    background-color: white;
  }
}

.ant-table-cell-fix-left, .ant-table-cell-fix-right {
  z-index: 11 !important;
}
.quill {
  height: 100%;
  padding-bottom: 50px;
}
/* Dot opacity loader */
.dot-opacity-loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.dot-opacity-loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #EF7752;
  margin: 35px 5px;
  opacity: 0;
}


.dot-opacity-loader span:nth-child(1) {
  animation: opacitychange 1s ease-in-out infinite;
}

.dot-opacity-loader span:nth-child(2) {
  animation: opacitychange 1s ease-in-out 0.33s infinite;
}

.dot-opacity-loader span:nth-child(3) {
  animation: opacitychange 1s ease-in-out 0.66s infinite;
}


@keyframes opacitychange {
  0%, 100% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}

.pagination .page-item .page-link {
  height: 38px;
  border-color: #E0E0E0;
}

.pagination .page-item.active .page-link {
  background: #29AB56;
  border-color: #29AB56;
}

.page-link:hover {
  background: #29AB56 !important;
  border-color: #29AB56 !important;
}

label.ant-form-item-required{
  &:after{
    display: inline-block !important;
    margin-inline-end: 4px !important;
    color: #ff4d4f !important;
    font-size: 14px !important;
    font-family: SimSun,sans-serif;
    line-height: 1 !important;
    content: "*" !important;
    visibility: inherit !important;
  }
  &:before{
    display: none !important;
    margin-inline-end: 0 !important;
    visibility: hidden !important;
  }
}


.tab_filter_header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4{
    font-size: 16px;
    font-weight: 600;
    color: #00AB56;
    margin-bottom: 0;
  }
}
a{
  text-decoration: none;
}

.send_request{
  width: 220px;
  height: 40px;
  display: flex;
  font-size: 16px;
  color: white;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  background: #29AB56;
  &:hover{
    color: white;
    background: #0d8632;
  }
}

.view_detail__btn {
  display: flex;
  margin: auto;
  width: 78px;
  height: 27px;
  padding: 3px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--active-success, #00AB56);
  color: var(--active-success, #00AB56);
  background-color: white;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
  &:hover {
    background: #00AB56;
    color: white;
  }
}
.text-no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #ccc8c8;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  font-style: italic;
}
.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}