#add_edit_product {
  .modal-dialog {
    max-width: 1200px;
    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .value_voucher__title {
      color:  #EF7752;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      text-transform: uppercase;
    }
  }
  .btn__voucher {
    background-color: #00AB56;
    color: white;
    width: 100%;
    height: 44px;
  }
  .list_image {
    width: 100%;
    height: 205px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 100%;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .list_ {
      min-width: 100%;
      width: 100%;
      height: 194px;
      display: flex;
      align-items: center;

      .upload {
        width: 170px;
        height: 174px;
        display: flex;
        margin-right: 16px;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer !important;
        border: 0.673077px dashed #DDDDE3;
        border-radius: 8px;
        background: #F5F5FA;

        input {
          width: 170px;
          height: 174px;
          opacity: 0;
          left: 0;
          z-index: 1000;
          cursor: pointer !important;
          overflow: hidden;
          position: absolute;

          &::-webkit-file-upload-button {
            width: 170px;
            height: 174px;
            opacity: 0;
            left: 0;
            z-index: 1000;
            cursor: pointer !important;
            position: absolute;
          }
        }
        img {
          margin: 150px;
        }
      }

      .item {
        width: 170px;
        height: 174px;
        margin-right: 16px;
        position: relative;

        .img {
          width: 168px;
          height: 172px;
          object-fit: cover;
        }

        .close_item {
          top: -12px;
          right: -12px;
          position: absolute;
          cursor: pointer;
        }
      }
    }
  }
}
.setting_supplies__product {
  padding-left: 18px;
  border-left: 1px solid #f3eeee;
  .setting_supplies__product_title {
    color: #00AB56;
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    line-height: 150%; /* 24px */
    margin-bottom: 18px;
  }
}
.button_add_supplies {
  display: flex;
  border: none;
  height: 28px;
  padding: 3px 12px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  background: #EAFFF4;
  color: var(--active-success, #00AB56);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  &:hover {
    opacity: 0.8;
  }
}
.supplies_product__container {
  max-height: 250px;
  margin-top: 20px;
  padding: 8px;
  overflow: scroll;

  &::-webkit-scrollbar {
    height: 100%;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #cdcdcd;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .supplies_item {
    padding-top: 18px;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    .supplies_item__name {
      overflow: hidden;
      color: #000;
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    input {
      border: none;
      outline: none;
      background: #F5F5F5;
      display: flex;
      width: 98px;
      padding: 3px 8px;
      color: var(--neutral-2, #4D4D4D);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    }
    .border_error {
      border: red;
      border-radius: 2px;
    }
    .button_delete__supplies {
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  }
  .no_supplies__container {
    font-style: italic;
    color: var(--neutral-2, #4D4D4D);;
  }
}
