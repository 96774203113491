#add_feedback {
  .modal-dialog {
    height: 100%;
    .modal-header {
      width: 100%;
      display: flex;
      padding: 36px;
      background: #F8F8F8;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 11px;
      .modal-header__container {
        padding: 0 50px;
      }
      .header__title {
        color: var(--B, #000);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
    }
    .modal-body {
      padding: 1rem 0;
      overflow: auto;
      .modal_header__subcontent {
        color: var(--main-b, #000);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
      }

      .modal-header__content_container {
        padding: 0 315px;
        margin-bottom: 36px;
      }
      .modal-header__info {
        display: flex;
        align-items: start;
        flex-wrap: wrap;
        justify-content: space-between;
        .form-group {
          width: calc(50% - 12px);
        }
      }
      .modal-header__content {
        display: flex;
        align-items: start;
        flex-wrap: wrap;
        gap: 24px;
        justify-content: start;
        .form-group {
          width: calc(50% - 12px);
        }
      }
    }
  }
  .btn__save {
    background-color: #00AB56;
    color: white;
    width: 180px;
    height: 44px;
  }
}
.card-feedback__container {
  width: calc(50% - 12px);
  height: auto;
  border-radius: 10px;
  padding: 15px;
  background: #F8F8F8;
  .card-feedback__header {
    display: flex;
    justify-content: space-between;
    .card-feedback__header_name {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
    }
  }
  .card-feedback__header_line {
    width: 100%;
    height: 1px;
    background: #C6C6C6;
    margin: 8px 0 16px;
  }
}