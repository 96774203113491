#add_edit_restaurant {
  .modal-dialog {
    max-width: 1000px;
    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .value_voucher__title {
      color:  #EF7752;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      text-transform: uppercase;
    }
  }
  .btn__voucher {
    background-color: #00AB56;
    color: white;
    width: 100%;
    height: 44px;
  }
}
