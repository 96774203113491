#setting_role {
  .modal-dialog {
    height: 100%;
    .modal-header {
      width: 100%;
      display: flex;
      padding: 36px 882px 37px 315px;
      background: #F8F8F8;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 11px;

      .modal-header__container {
        padding: 0 315px;
      }
      .header__title {
        color: var(--B, #000);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
    }
    .modal-body {
      padding: 1rem 0;
      .modal-header__content {
        padding: 0 315px;
        display: flex;
        align-items: start;
        flex-wrap: wrap;
        gap: 24px;
        justify-content: start;
      }
    }
  }
  .btn__save {
    background-color: #00AB56;
    color: white;
    width: 180px;
    height: 44px;
  }
}
.card-role__container {
  width: calc(50% - 12px);
  height: auto;
  border-radius: 10px;
  padding: 15px;
  background: #F8F8F8;
  .card-role__header {
    display: flex;
    justify-content: space-between;
    .card-role__header_name {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
      text-transform: capitalize;
    }
  }
  .card-role__header_line {
    width: 100%;
    height: 1px;
    background: #C6C6C6;
    margin: 8px 0 16px;
  }
}
