#detail_customer_data {
  .modal-dialog {
    max-width: 1400px !important;
  }
  .modal-dialog {
    .modal-content {
      padding: 30px ;
      .modal-header {
        padding: unset;
      }
      .modal-customerData--content {
        .modal-customerData--head {
          display: flex;

          .modal-customerData--headFirst {
            width: 100%;
            .modal-customerData--title {
              width: 200px;
              display: flex;
              padding: 2px 0;
              margin-bottom: 10px;
              align-items: flex-start;
              gap: 10px;
              border-bottom: 1px solid var(--gray-5, #A6A6B0);
              color: #64646D;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
            }
            .modal-customerData--name {
              width: 90px;
              color:  #64646D;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 18px */
            }
            .modal-customerData--value {
              color: #000;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 18px */
            }
            span {
              line-height: 180%;
            }
          }
        }
      }
    }
  }
}

