.button_cancel__formtable {
  border: none;
  margin: 0 8px;
  display: flex;
  width: 89px;
  padding: 5.5px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #E0E0E0;
  color: var(--main-b, #000);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  &:hover {
    opacity: 0.8;
  }
}
.button_save__formtable {
  border: none;
  margin: 0 8px;
  display: flex;
  width: 89px;
  padding: 5.5px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #00AB56;
  color: var(--main-b, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  &:hover {
    opacity: 0.8;
  }
}

.kpi_page{
  .btn_add:hover{
    color: white;
  }
  .data_kpi{
    overflow: hidden;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
    .table-responsive{
      border-radius: 0;
      box-shadow: none;
    }
    .col_table > div{
      font-size: 14px;
      font-weight: 400;
      line-height: 22px !important;
      white-space: normal !important;
      padding: 10px 12px !important;
    }
  }

  .action_edit{
    width: 100%;
    height: 72px;
    align-items: center;
    background-color: white;
    position: fixed;
    z-index: 1200;
    bottom: 0;
    right: 0;
    padding: 0 30px;
    button{
      height: 32px;
    }
  }
}