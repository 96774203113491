.create_rnd_research__container {
  padding: 18px 48px 48px;
  .data_header_research__wrap {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    .data_header_research__wrap--key {
      color: #000;
      text-align: justify;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-right: 12px;
      width: 160px;
    }
    .data_header_research__wrap--input {
      padding: 4px 8px;
      border: none;
      outline: none;
      background: rgb(245, 243, 243);
      color: #454546;
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
  }
  .create_rnd_research__title{
    display: flex;
    margin-top: 18px;
    p {
      color: #000;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 135%; /* 43.2px */
      text-transform: uppercase;
    }
    .create_rnd_research__status {
      display: flex;
      width: 95px;
      height: 28px;
      padding: 10px;
      margin: 0 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 2px;
      background: #EFEFEF;
      color: var(--grey-3, #747474);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
    }
    .create_rnd_research__status--pending {
      display: flex;
      width: 95px;
      height: 28px;
      padding: 10px;
      margin: 0 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 2px;
      background: rgba(120, 98, 236, 0.14);
      color: var(--grey-3, #7B61FF);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
    }
  }
  .device_star {
    color: #000;
    text-align: justify;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  .title_table__research {
    color: #000;
    text-align: justify;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }
  .subtitle_table__research {
    color: #000;
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    margin-bottom: unset;
  }

  .button_save_research {
    display: flex;
    margin-bottom: 80px;
    border: none;
    outline: none;
    width: 155px;
    height: 44px;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 2px;
    background: var(--main, #00953D);
    color: var(--w, #FFF);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    &:hover {
      background: var(--main, #027732);
    }
  }


}