.btn_sumary__request {
    border: none;
    display: flex;
    width: 189px;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #EF7752;
    color: var(--main-b, #fff);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    text-decoration: none;
    &:hover {
      background: #c95b39;
      color: var(--main-b, #fff);
    }
}
.summary_purchase_page {
    .tab_filter_header {
        a {
            text-decoration: none;
            background: transparent;
            border: none;
            display: flex;
            align-items: center;
        }
    }
}
