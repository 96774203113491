.service_container {
  padding-bottom: 100px;
  .service_container__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.service_item_container {
  td {
    background-color: rgba(241, 239, 239, 0.86);
  }
}
.button_qty__item-order {
  border: 1px solid rgba(206, 206, 210, 0.58);
  background-color: rgba(219, 219, 224, 0.28);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 12px;
  }
  &:hover {
    background-color: rgba(206, 206, 210, 0.53);
  }
}
.btn_select_restaurant {
  border: none;
  display: flex;
  width: 189px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #EF7752;
  color: var(--main-b, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  &:hover {
    background: #c95b39;
  }
}

.back_order__detail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  background: #e7ebee;
  padding: 4px 16px;
}
.service_detail__container {
  width: 75%;
  display: flex;
  flex-direction: column;

  .service_detail__container--header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 12px;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgba(98, 108, 112, 0.25);
    background: #FFF;
    .service_detail__header--item {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      .service_detail__header--item-timeTitle {
        color: var(--neutral-04, #6F767E);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 200% */
        letter-spacing: -0.12px;
      }
      .service_detail__header--item-timeValue {
        color: var(--Main, #EF7752);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 135%; /* 27px */
        letter-spacing: -0.2px;
      }
      .service_detail__header--item-container {
        display: flex;
        justify-content: space-between;
        margin: 4px 0;
        .service_detail__header--item-left {
          color: var(--neutral-04, #6F767E);
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 160% */
          letter-spacing: -0.15px;
        }
        .service_detail__header--item-right {
          color: var(--neutral-07, #1A1D1F);
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 160% */
          letter-spacing: -0.15px;
        }
      }
      .check_out__order--btn {
        display: flex;
        padding: 8px 15px;
        justify-content: center;
        align-items: center;
        gap: 16.889px;
        border-radius: 8.444px;
        background: var(--Main, #EF7752);
        color: #FFF;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        &:hover {
          background: var(--Main, #e04b1c);
        }
      }
    }
  }
}
.detail_order__action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn_add__item {
    border: none;
    outline: none;
    display: flex;
    width: 151px;
    height: 44px;
    padding: 5.5px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: var(--active-success, #00AB56);
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    &:hover {
      background-color: #037a3f;
    }
  }
  .btn_switch__table {
    border: none;
    outline: none;
    display: flex;
    width: 130px;
    height: 44px;
    padding: 3px 12px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 4px;
    background: rgba(210, 210, 210, 0.78);
    color: var(--M1, #c9cdce);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    &:hover {
      background-color: #bbbebe;
    }
  }
}

