#detail_feedback {
  .modal-body {
    padding: 1rem 0 0;
  }
  .modal-dialog {
    max-width: 800px;
    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .detail_feedback__title {
      color:  #EF7752;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      text-transform: uppercase;
    }
  }
  .stock_wrap__key--active {
    color: #29ab56;
    font-weight: 500;
  }

}
