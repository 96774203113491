

.button_add_research {
  display: flex;
  text-decoration: none;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #00AB56;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  &:hover {
    color: white;
    opacity: 0.8;
  }
}