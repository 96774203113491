#detail_checkout_order {
  .modal-dialog {
    max-width: 1400px !important;
  }
  .modal-dialog {
    .modal-content {
      padding: 30px ;
      .modal-header {
        padding: unset;
      }
      .modal-order--content {
        .modal-order--head {
          display: flex;
          justify-content: start;
          margin-top: 10px;

          .modal-order_checkout--headFirst {
            width: 290px;
            .modal-order--title {
              display: flex;
              width: 190px;
              padding: 10px 0;
              margin-bottom: 10px;
              align-items: flex-start;
              gap: 10px;
              border-bottom: 1px solid var(--gray-5, #A6A6B0);
              color: #64646D;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
            }
            .modal-order--name {
              width: 90px;
              color:  #64646D;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 18px */
            }
            .modal-order--value {
              color: #000;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 18px */
            }
            span {
              line-height: 180%;
            }
          }
          .modal-order_checkout--headSecond {
            width: 495px;
            margin-left: 25px;
            .modal-order--title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              padding: 2px 0;
              margin-bottom: 10px;
              gap: 10px;
              border-bottom: 1px solid var(--gray-5, #A6A6B0);
              color: #64646D;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              button {
                border: none;
                outline: none;
                background: transparent;
              }
            }
            .modal-order--name {
              color:  #64646D;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 18px */
            }
            .modal-order--value {
              color: #000;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%; /* 18px */
            }
            span {
              line-height: 180%;
            }
          }
        }
      }
    }
    .modal-footer {
      padding: 0.75rem 0;
    }
    .btn_action__checkout {
      display: flex;
      margin-left: 12px;
      height: 40px;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid var(--active-success, #00AB56);
      color: var(--active-success, #00AB56);
      background-color: white;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-decoration: none;
      &:hover {
        opacity: 0.8;
      }
    }
    .btn_action__checkout__finish {
      display: flex;
      margin-left: 12px;
      height: 40px;
      padding: 8px 36px;
      width: 250px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: none;
      color: white;
      background-color: #00AB56;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-decoration: none;
      &:hover {
        opacity: 0.8;
      }
    }
    .input-search-container {
      display: flex;
      width: 320px;
      height: 32px;
      padding: 8px;
      align-items: center;
      gap: 8px;
      border-radius: 30px;
      border: 1px solid #FFF;
      background: #f8f5f5;
      img {
        cursor: pointer;
      }

      .search-input {
        border: none;
        outline: none;
        background: transparent;
        color: #5e6e78;
        width: 100%;
      }
    }
    .input-search-voucher-container {
      display: flex;
      justify-content: space-between;
      width: 250px;
      height: 32px;
      padding: 8px;
      align-items: center;
      gap: 8px;
      border-radius: 30px;
      border: 1px solid #FFF;
      background: #f8f5f5;
      img {
        cursor: pointer;
      }

      .search-input {
        border: none;
        outline: none;
        background: transparent;
        color: #5e6e78;
      }
    }
  }

  .button_qty__item-order {
    border: none;
    outline: none;
    background: rgba(238, 239, 241, 1);
    border-radius: 4px;
    border: 1px solid #c5c5c5;
    margin: 0 8px;
    img {
      width: 12px;
    }
    &:hover {
      background: rgba(238, 239, 241, 0.6);
    }
  }
  .search_user__container {
    background: white;
    border-radius: 4px;
    margin-top: 6px;
    overflow-y: scroll;
    padding-left: unset!important;
    z-index: 99999999;
    max-height: 400px;
    width: 350px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    .search_user__item {
      list-style-type: none;
      padding: 6px 12px;
      &:hover {
        background: #f6cfab;
      }
    }
  }

  .input_fee__shipping {
    border: none;
    outline: none;
    background-color: rgba(192, 187, 187, 0.3);
    padding: 4px 10px;
    width: 120px;
    font-size: 14px;
    text-align: end;
  }
  .box_no__user {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5e6e78;
    font-weight: 500;
    font-style: italic;
  }
  @media print {
    body * {
      visibility: hidden;
    }
    #detail_checkout_order * {
      visibility: visible;
    }
    .btn_action__checkout * {
      visibility: hidden !important;
    }
    .input-search-container * {
      visibility: hidden !important;
    }
  }
}

