#detail_order {
  .modal-dialog {
    max-width: 1400px !important;
  }
}
.modal-dialog {
  .modal-content {
    padding: 30px ;
    .modal-header {
      padding: unset;
    }
    .modal-order--content {
      .modal-order--head {
        display: flex;

        .modal-order--headFirst {
          width: 290px;
          .modal-order--title {
            display: flex;
            width: 190px;
            padding: 2px 0;
            margin-bottom: 10px;
            align-items: flex-start;
            gap: 10px;
            border-bottom: 1px solid var(--gray-5, #A6A6B0);
            color: #64646D;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
          .modal-order--name {
            width: 90px;
            color:  #64646D;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 18px */
          }
          .modal-order--value {
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 18px */
          }
          span {
            line-height: 180%;
          }
        }
        .modal-order--headSecond {
          width: 495px;
          margin-left: 100px;
          .modal-order--title {
            display: flex;
            width: 100%;
            padding: 2px 0;
            margin-bottom: 10px;
            align-items: flex-start;
            gap: 10px;
            border-bottom: 1px solid var(--gray-5, #A6A6B0);
            color: #64646D;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
          .modal-order--name {
            color:  #64646D;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 18px */
          }
          .modal-order--value {
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 18px */
          }
          span {
            line-height: 180%;
          }
        }
      }
    }
  }
}
