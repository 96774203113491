:root {
  --gradient-start: #C84E89;
  --gradient-end: #F15F79;
}
.signUp_main {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .background-signup__container {
    position: relative;
  }
  .header_register {
    color: var(--black-white-b, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    .header_register__logout {
      border: none;
      display: flex;
      text-decoration: none;
      justify-content: center;
      align-items: center;
      margin-left: 28px;
      background: transparent;
      color: var(--grey-3, #747474);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
    }
  }
  .content-signup {
    width: 100%;
    height: 85%;
    padding: 20px 50px 50px;
    display: flex;
    align-items: center;
    .content-signup__container {
      h1 {
        color:  #EF7752;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }
      .content-signup__subtitle {
        font-size: 18px;
        font-style: normal;
        margin-left: 5px;
        font-weight: 500;
        line-height: 150%;
      }
      .content-signup__btn {
        display: flex;
        flex-direction: column;
        align-items: start;
        button {
          padding: 6px;
          margin-top: 12px;
          border: none;
          outline: none;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          &:hover {
            opacity: 0.8;
          }
        }
        .btn-redirect {
          width: 245px;
          height: 48px;
          background: #EF7752;
          color: #FFF;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }
        .btn-redirect-register {
          background: white;
          color: #515158;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }
        .btn-redirect-register__noList {
          display: flex;
          width: 245px;
          height: 48px;
          padding: 8px 12px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          color: #FFF;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 24px */
          border-radius: 4px;
          background: var(--Main, #EF7752);
        }
      }
    }
    .block_account__container {
      margin-top: 100px;
      h1 {
        color: var(--RED, #E60A32);
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 47.5px; /* 131.944% */
      }
      p {
        color: #5F6368;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-left: 5px;
        line-height: 24px; /* 150% */
      }
      .btn-reload_block__container {
        border: none;
        border-radius: 4px;
        background: var(--Main, #EF7752);
        display: flex;
        width: 220px;
        height: 48px;
        padding: 15px 25px;
        justify-content: center;
        align-items: center;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        letter-spacing: 0.1px;
        img {
          margin-right: 4px;
        }
      }
    }
  }

  .register_container {
    width: 100%;
    .btn_back {
      border: none;
      background: transparent;
      padding: unset;
      color: var(--black-white-b, #000);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
    }
    .register_title {
      color: var(--grayscale-g-1, #333);
      text-align: justify;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 26px */
      padding: 8px 0;
      border-bottom: 1px solid #DBDEE5;
    }
    .button_register {
      border: none;
      display: flex;
      width: 354px;
      padding: 14px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 2px;
      background: #29AB56;
      color: var(--black-white-w, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
      text-transform: capitalize;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.select_account__container {
  max-height: 280px;
  overflow-y: scroll;
}
.card_restaurant{
  height: 70px;
  width: 100%;
  position: relative;
  background: #FFF;
  box-shadow: 3px 2px 3px 0 rgba(0, 0, 0, 0.04);
  h5{
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 5px;
  }
  h2{
    color: var(--gray-7, #64646D);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin: unset;

  }
  input[type="radio"]{
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    height: 100%;
    width: 100%;
    position: absolute;
    box-shadow: 3px 2px 3px 0 rgba(0, 0, 0, 0.04);
    cursor: pointer;
    outline: none;
  }
  input[type="radio"]:before{
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    background-color: #f9fafd;
    border: 1px solid #e2e6f3;
    border-radius: 50%;
    top: 24px;
    right: 20px;
  }
  input[type="radio"]:after{
    content: "";
    position: absolute;
    height: 13px;
    width: 13px;
    background-color: transparent;
    border-radius: 50%;
    top: 28.5px;
    right: 24.4px;
  }
  label{
    width: auto;
    height: 100%;
    position: absolute;
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 15px;
    .card_restaurant__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
    }
  }
  input[type="radio"]:checked{
    box-shadow: 3px 2px 3px 3px rgba(0, 0, 0, 0.1);
  }
  input[type="radio"]:checked:after{
    background-color: #00AB56;
  }
  .card_restaurant__image {
    width: 52px;
    height: 52px;
    margin-right: 12px;
    img {
      border: 1px solid #000;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

}
