.order_ship__container {
  background: #FAFAFA;
  padding: 18px 168px;
  .header_order__ship {
    display: flex;
    padding: 8px 18px;
    justify-content: center;
    align-items: center;
    background: #FFF;

    a {
      color: #414141;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .order_code {
      color: #414141;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
       margin-left: 12px;
    }
    .order_time {
      color: #929292;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .tag-status {
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      display: flex;
      height: 28px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 3px;
      margin: 0 8px;
    }
    .tag-pending {
      color: var(--gray-9, #38383D);
      background: rgba(228, 232, 228, 0.65);
    }
    .tag-purple {
      color: #7B61FF;
      background-color: #e7e5f1;
    }
    .tag-green {
      color: #00953D;
      background: #E9FFE7;
    }
    .tag-red {
      color: #FF424F;
      background-color: #f5e7e8;
    }
    .select_status__order--shipping {
      width: 150px !important;
    }
  }
  .tracking_order__status {
    background: #fff;
    padding: 28px 0;
  }
  .des_info__order {
    background-color: #fff;
    padding: 8px 18px;
    .des_info__order--left {
      width: 200px;
      color: #414141;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-right: 10px;
    }
    .des_info__order--right {
      width: auto;
      color: #414141;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-left: 10px;
    }
    .detail_order__customer {
      width: 100%;
      height: 40px;
      padding: 8px;
      flex-shrink: 0;
      border-radius: 4px;
      background: #F8F8F8;
      .detail_order__customer--name {
        color: #414141;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .detail_order__customer--btn {
        border: none;
        outline: none;
        background: transparent;
        color: #1E9A57;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 130%; /* 18.2px */
        text-decoration-line: underline;
        text-transform: capitalize;
      }
    }
    .order_item__container {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #EFEFEF;
      padding: 10.5px 7px 9.5px 0px;
      align-items: center;
      .order_item__container--product {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        p {
          color: #666;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
        span {
          color: #848484;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 18.2px */
        }
      }
      .order_item__container--price {
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        p {
          color: var(--gray-8, #515158);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
        }
        span {
          color: #A3A3A3;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 18.2px */
          text-decoration-line: line-through;
        }
      }
      .order_item__container--qty {
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        p {
          color: var(--gray-8, #515158);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 22.4px */
        }
      }
      .order_item__container--total {
        width: 10%;
        display: flex;
        justify-content: end;
        p {
          color: var(--gray-8, #515158);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }
        span {
          color: var(--gray-8, #515158);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          text-decoration-line: underline;
          margin-left: 4px;
        }
      }
    }
    .sumary__order {
      width: 36%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 16px;
      .title_sumary__order {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
      }

      p {
        color: #414141;
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        margin-bottom: unset !important;
        span {
          color: #414141;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-decoration-line: underline;
        }
      }
      .sum_price {
        color: var(--positive-1, #00BC3C);
        text-align: right;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        span {
          color: var(--positive-1, #00BC3C);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-decoration-line: underline;
        }
      }
    }
    .line-dashed {
      width: 100%;
      border: 0.5px dashed #c7c2c2; /* Để tạo không gian giữa các đoạn dashed */
    }
  }
  .print_order_bill--btn {
    display: flex;
    background: transparent;
    width: 217px;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--gray-9, #38383D);
    &:hover {
      background: rgba(239, 237, 237, 0.66);
    }
  }
  .convert_status__transport--container {
    display: flex;
    .btn_cancel__transport {
      display: flex;
      width: 131px;
      height: 48px;
      padding: 10px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      background: var(--gray-2, #EBEBF0);
      color: var(--gray-10, #27272A);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      margin: 0 10px;
    }
    .btn_transport {
      display: flex;
      width: 131px;
      height: 48px;
      padding: 10px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      background: #00AB56;
      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
  }


}
@media print {
  body * {
    visibility: hidden;
  }
  #order_ship__container * {
    visibility: visible;
  }
  .btn_print * {
    visibility: hidden !important;
  }
  .select_status__order--shipping * {
    visibility: hidden !important;
  }
  .detail_order__customer--btn * {
    visibility: hidden !important;
  }
}