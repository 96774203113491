.order_ship{
  width: 100%;
  padding: 18px;
  border-radius: 10px;
  background-color: white;
  .w_1_3{
    width: 33.333333%;
  }
  .w_1_6{
    width: 16.666667%;
  }
  .header_top{
    width: 100%;
    height: 48px;
    .item_{
      height: 100%;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid white;
      background-color: #ECF0FF;
    }
  }

  .item_order{
    width: 100%;
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid #E8E8E8;
    .info_shop{
      width: 100%;
      height: 40px;
      display: flex;
      padding: 0 12px;
      align-items: center;
      justify-content: space-between;
      background-color: #E8E8E8;
      .name{
        font-size: 18px;
        color: #414141;
        font-weight: 500;
        padding-right: 12px;
        margin-right: 12px;
        border-right: 1px solid #414141;
      }
      }
    .phone{
      font-size: 18px;
      color: #414141;
    }
    .left{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 300px;
      .code{
        font-size: 16px;
        color: #414141;
        margin-right: 15px;
        display: inline-flex;
      }
      .time{
        font-size: 14px;
        color: #4D4D4D;
      }
    }
    .text-state__order {
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 171.429% */
    }
    .text-state__order--sub {
      color: var(--neutral-2, #4D4D4D);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 200% */
    }
  }
  .detail_order__btn {
    color: var(--Main, #EF7752);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }
    .item_product{
      width: 100%;
      height: 72px;
      padding: 2px 10px;
      .item_product__container {
        span {
          color: #000;
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
        }
      }
    }
  .address_receiver {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }
  }
