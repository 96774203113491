body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
li,i {
  cursor: pointer;
}
a{
  color:unset;
}
a:hover{
  color: unset;
  text-decoration: unset;
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url('../public/fonts/roboto-v20-vietnamese_latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('../public/fonts/roboto-v20-vietnamese_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../public/fonts/roboto-v20-vietnamese_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../public/fonts/roboto-v20-vietnamese_latin-500.woff') format('woff'), /* Modern Browsers */
  url('../public/fonts/roboto-v20-vietnamese_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../public/fonts/roboto-v20-vietnamese_latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
