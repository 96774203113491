.btn-update__content-work {
    background-color: white;
  border-color: #29ab56;
    color: #29ab56;
  padding: 6px 16px;
    &:hover {
      background-color: #29ab56;
      color: white;
    }
}
.business_plan {
  .ant-table-content {
    overflow: unset !important;
  }
}
.business_plan__detail {
  .ant-table-content {
  }
}