.stock_container__content {
  padding: 22px 54px;
  .stock_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    margin-bottom: 20px;
    .stock_wrap__title {
      color: var(--main-b, #000);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
    }
    .stock_wrap__subtitle {
      color: var(--gray-7, #64646D);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      .stock_wrap__key {
        color: var(--main-b, #000);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
    }
  }
}