#update_content_job_plan {
  .modal-dialog {
    max-width: 1000px;
    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .value_voucher__title {
      color:  #EF7752;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 24px */
      text-transform: uppercase;
    }
  }
  .btn__voucher {
    background-color: #00AB56;
    color: white;
    width: 100%;
    height: 44px;
  }
  .list_image {
    width: 100%;
    height: 138px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 100%;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cdcdcd;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .list_ {
      min-width: 100%;
      width: 100%;
      height: 124px;
      display: flex;
      align-items: center;

      .upload {
        width: 100px;
        height: 104px;
        display: flex;
        margin-right: 16px;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer !important;
        border: 0.673077px dashed #DDDDE3;
        border-radius: 8px;
        background: #F5F5FA;

        input {
          width: 100px;
          height: 104px;
          opacity: 0;
          left: 0;
          z-index: 1000;
          cursor: pointer !important;
          overflow: hidden;
          position: absolute;

          &::-webkit-file-upload-button {
            width: 170px;
            height: 174px;
            opacity: 0;
            left: 0;
            z-index: 1000;
            cursor: pointer !important;
            position: absolute;
          }
        }
        img {
          margin: 150px;
        }
      }

      .file_item {
        width: 100px;
        height: 104px;
        margin-right: 16px;
        position: relative;
      }
      .item {
        width: 100px;
        height: 104px;
        margin-right: 16px;
        position: relative;

        .img {
          width: 98px;
          height: 102px;
          object-fit: cover;
        }

        .close_item {
          top: -12px;
          right: -12px;
          position: absolute;
          cursor: pointer;
        }
      }
    }
  }
}

