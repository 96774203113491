.approve_footer__container {
  background: #FFF;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.20);
  height: 68px;
  button {
    border: none;
    color: var(--main-w, #FFF);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    display: flex;
    width: 82px;
    height: 36px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
  }
  .reject_request__btn {
    background: var(--active-error, #FF424F);
    margin: 0 12px;
    &:hover {
      background: var(--active-error, #e53541);
    }
  }
  .approve_request__btn{
    background: var(--active-success, #00AB56);
    &:hover {
      background: var(--active-success, #068a48);
    }
  }
}