#detail_request_purchase, #popup_imp_exp_request, #confirm_supplies_import{
  .modal-dialog {
    max-width: 1200px;
  }
  .bt-action-print {
    background-color: transparent;
    border: none;
    outline: none;
  }
  .detail_request_purchase__title {
    color: var(--main-b, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 21px */
  }
  .detail_request_purchase__content {
    color: var(--main-b, #000);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
    text-transform: uppercase;
  }
  .modal-order--content {
    .modal-order--head {
      display: flex;
      margin-bottom: 18px;
      .modal-order--headFirst {
        width: auto;
        .modal-order--name {
          width: 150px;
          color:  #64646D;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 18px */
        }
        .modal-order--value {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 18px */
        }
        span {
          line-height: 180%;
        }
      }
      .modal-order--headSecond {
        width: auto;
        margin-left: 60px;
        .modal-order--name {
          width: 110px;
          color:  #64646D;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 18px */
        }
        .modal-order--value {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 18px */
        }
        span {
          line-height: 180%;
        }
      }
    }
  }
}
@media print {
  body * {
    visibility: hidden;
  }
  #detail_request_purchase * , #popup_imp_exp_request *{
    visibility: visible;
  }
  .bt-action-print * {
    visibility: hidden;
  }
}
