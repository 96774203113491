.button_cancel__formtable {
  border: none;
  margin: 0 8px;
  display: flex;
  width: 89px;
  padding: 5.5px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #E0E0E0;
  color: var(--main-b, #000);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  &:hover {
    opacity: 0.8;
  }
}
.btn_select_restaurant {
  border: none;
  display: flex;
  width: 189px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #EF7752;
  color: var(--main-b, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  &:hover {
    background: #c95b39;
  }
}
.button_save__formtable {
  border: none;
  margin: 0 8px;
  display: flex;
  width: 89px;
  padding: 5.5px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #00AB56;
  color: var(--main-b, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  &:hover {
    opacity: 0.8;
  }
}